import React from "react";
import PropTypes from "prop-types"


import "../news-preview/news-preview.sass"
import {absolutePathToSlug} from "../../util/absolutePathToSlug";
import {toPath} from "../../util/toPath";

const NewsPreview = (props) => {
    const {node} = props;

    return (
        <div className={'news-preview'}>
            <a href={`/aktuelles/${absolutePathToSlug(node.fileAbsolutePath)}`}
               className={'headline large-link'}>{node.frontmatter.title}</a>
            <div className="content">

                {node.frontmatter.thumbnail &&
                <a href={`/aktuelles/${absolutePathToSlug(node.fileAbsolutePath)}`} className={'thumbnail-link'}><img
                    className={'thumbnail'} src={toPath("_prev", node.frontmatter.thumbnail)} alt=""/>
                </a>
                }

                <div className="preview-text">
                    <h6 className={'slug'}>{node.frontmatter.subtitle}</h6>
                    <p className="text" dangerouslySetInnerHTML={{__html: node.rawMarkdownBody}}></p>
                    <a href={`/aktuelles/${absolutePathToSlug(node.fileAbsolutePath)}`} className="primary">Weiterlesen</a>
                </div>
            </div>
        </div>
    )
};

NewsPreview.propTypes = {
    children: PropTypes.node,
};

export default NewsPreview
