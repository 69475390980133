import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import NewsPreview from "../components/news-preview/news-preview";
import {graphql} from "gatsby";

const AktuellesPage = ({data}) => (
    <Layout>
        <SEO title="Aktuelles"/>
        <div className={'container section bottom'}>

            <h1 className={'title'}>AKtuelles</h1>
            <h4 className={'subtitle'}>Aktuelle Mitteilungen
            </h4>
            <div className="third-container">
                {data.allMarkdownRemark.edges.map((item, i) => (
                        <NewsPreview key={i} node={item.node}></NewsPreview>
                    ))}
            </div>
        </div>
    </Layout>
);

export const pageQuery = graphql`
    query BlogIndexQuery {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "//blog//"}}
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
      ) {
        edges {
          node {
            rawMarkdownBody
            fileAbsolutePath
            frontmatter {
              date
              title
              subtitle
              thumbnail
            }
          }
        }
      }
    }
  `;

export default AktuellesPage
